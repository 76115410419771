import { createRoot } from 'react-dom/client'
/** Styles for syntax highlighting */
import wrapWithProvider from './gatsby-wrap-with-provider';

/** See https://github.com/gatsbyjs/gatsby/issues/8237#issuecomment-448885104 */
export const replaceHydrateFunction = () => (element, container, callback) => {
    const root = createRoot(container)
    root.render(element)
}
export const wrapRootElement = wrapWithProvider
